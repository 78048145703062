<template>
  <div id="app">
    <section class="white">
      <Header/>
    </section>
    <router-view/>
    <section>
      <Footer/>
    </section>
  </div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>


<style lang="scss">
</style>