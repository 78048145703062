<template>
  <footer>
    <div class="container">
    <div class="footer-row">
      
      <div class="col-xs-12 col-sm-12 col-md-6 text-start">

        <ul class="links">
          <li>
            <a v-bind:href="$t('links.tietosuoja-url')" target="_blanc">
                  {{$t('links.tietosuoja-label')}}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 text-end">
        <div class="some-buttons">
    <h5 class="some-buttons__title">Seuraa meitä</h5>
            <a href="https://www.facebook.com/oomienergia/" target="_blank">
            <i class="fab fa-facebook-square"></i>
        </a>
    
            <a href="https://www.instagram.com/oomienergia/" target="_blank">
            <i class="fab fa-instagram"></i>
        </a>
    
            <i class="fab fa-twitter"></i><a href="https://twitter.com/EnergiaOomi" target="_blank">
            
        </a>
    
            <a href="https://www.linkedin.com/company/oomienergia/" target="_blank">
            <i class="fab fa-linkedin-in"></i>
        </a>
    
            <a href="https://www.youtube.com/channel/UCm7a2kaxTZU-BWcrv6XriTA" target="_blank">
            <i class="fab fa-youtube"></i>
        </a>
    </div>
      </div>
    </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return { 
    }
  },
  methods: {
  }
};
</script>

<style>
</style>