<template>
<div>
  <div class="color-container hero">
    <div class="container">
      <div class="content ">
      <h1 class="text-start hero-heading" v-html="$t('heading-1')"></h1>
      </div>
    </div>
  </div>
  
  
  <section class="">
    <div class="container pt-2 pb-5">
      
      <div class="box dark-forest ingress text-start" v-html="$t('ingress')">
      </div>
      <div class="box beige text-start">
        <h2>{{$t('heading-2')}}</h2>

        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title" for="radio_bike_type_1">{{$t('form.field-1.label')}}:</label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 d-flex rb-flex">
              <label class="v-radio-button-field">
                <div class="v-radio-button"  v-bind:class="{ 'v-radio-button-checked': form.bike_type === '1'}">
                  <input type="radio"  id="radio_bike_type_1" value="1" v-model="form.bike_type" @change="checkBikeType()">
                </div>
                <span class="w-form-label">{{$t('form.field-1.option-1')}}</span>
              </label>
              <label class="v-radio-button-field">
                <div class="v-radio-button" v-bind:class="{ 'v-radio-button-checked': form.bike_type === '0'}">
                <input type="radio" id="radio_bike_type_0" value="0" v-model="form.bike_type"  @change="checkBikeType()">
                </div>
                <span class="w-form-label">{{$t('form.field-1.option-2')}}</span>
              </label>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title" for="input-field-bike-price">
              {{$t('form.field-2.label')}}:
              <popper trigger="hover" :options="{placement: 'top'}" v-if="$t('form.field-2.info')">
                <div class="popper">
                  {{$t('form.field-2.info')}}
                </div>
                <!-- <span class="infocircle">?</span> -->
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper>
            </label>
            
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6">
            <input type="number" class="input-field w-input" v-model="form.bike_price" maxlength="256" placeholder="Paketin hinta" id="input-field-bike-price">
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title" for="input-field-salary" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
              {{$t('form.field-3.label')}}:
            </label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6">
            <input type="number" class="input-field w-input" v-model="form.salary" v-on:keyup="lookupTax()"
              placeholder="Palkka" id="input-field-salary" maxlength="256"  data-name="input-field"  >
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title" for="input-field-service">{{$t('form.field-4.label')}}:
              <popper trigger="hover" :options="{placement: 'top'}">
                <div class="popper" v-html="$t('form.field-4.info')"></div>
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper></label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="slidecontainer mb-3">
                <vue-slider v-model="form.service_budget" :tooltip="'always'" :adsorb="true" 
                   :min="0" :max="20" :tooltip-formatter="settings.format_e_kk"
                  :data="settings.service_budgets"
                  :marks="true" @change="lookupGuarantee()"></vue-slider>       
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title" for="input-field-benefit">{{$t('form.field-5.label')}}:
              <popper trigger="hover" :options="{placement: 'top'}">
                <div class="popper" v-html="$t('form.field-5.info')"></div>
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper>
            </label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6">      
            <input type="number" class="input-field w-input" min="0" max="100" v-model="form.employer_benefit"
             placeholder="Etu palkan päälle" id="input-field-benefit">
             
            <p v-if="!$v.form.employer_benefit.between" class="error">
              <span >{{$t('form.field-5.error')}}</span>
            </p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title">{{$t('form.field-6.label')}}:
              <popper trigger="hover" :options="{placement: 'top'}">
                <div class="popper">
                  {{$t('form.field-6.info')}}
                </div>
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper></label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="slidecontainer">
                <vue-slider v-model="form.agreement_period" :tooltip="'always'" :adsorb="true" 
                   :min="6" :max="60" :tooltip-formatter="settings.format" @change="lookupGuarantee()"></vue-slider>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title" for="input-field-radio-eii">{{$t('form.field-7.label')}}:
            </label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 d-flex">              
              <label class="v-radio-button-field">
                <div class="v-radio-button" v-bind:class="{ 'v-radio-button-checked': form.employer_interruption_insurance === '1'}">
                  <input type="radio" id="input-field-radio-eii" value="1" v-model="form.employer_interruption_insurance"  @change="setEmployer_interruption_insurance()">
                </div>
                <span class="w-form-label">{{$t('options.yes')}}</span>
              </label>
              <label class="v-radio-button-field">
                <div class="v-radio-button" v-bind:class="{ 'v-radio-button-checked': form.employer_interruption_insurance === '0'}">
                <input type="radio" value="0" v-model="form.employer_interruption_insurance"   @change="setEmployer_interruption_insurance()">
                </div>
                <span class="w-form-label">{{$t('options.no')}}</span>
              </label>
          </div>
        </div>
        <div class="row mb-4" v-if="form.employer_interruption_insurance === '0'">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title">{{$t('form.field-8.label')}}:</label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 d-flex">
              <label class="v-radio-button-field">
                <div class="v-radio-button" v-bind:class="{ 'v-radio-button-checked': form.interruption_insurance === '1'}">
                  <input type="radio" data-name="Radio" id="radio" value="1" v-model="form.interruption_insurance" @change="_calKeskeytysturva()" >
                </div>
                <span class="w-form-label">{{$t('options.yes')}}</span>
              </label>
              <label class="v-radio-button-field">
                <div class="v-radio-button" v-bind:class="{ 'v-radio-button-checked': form.interruption_insurance === '0'}">
                  <input type="radio" data-name="Radio" id="radio" value="0" v-model="form.interruption_insurance" @change="_calKeskeytysturva()">
                </div>
                <span class="w-form-label">{{$t('options.nope')}}</span>
              </label>

          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xs-12 col-sm-12 col-md-6">
            <label class="title" for="input-field-radio-guarantee">{{$t('form.field-9.label')}}: 
              <popper trigger="hover" :options="{placement: 'top'}">
                <div class="popper">
                  {{$t('form.field-9.info')}}
                </div>
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper>
            </label>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 d-flex">
              <label class="v-radio-button-field">
                <div class="v-radio-button" v-bind:class="{ 'v-radio-button-checked': form.guarantee === '1'}">
                  <input type="radio" data-name="Radio" id="input-field-radio-guarantee" value="1" v-model="form.guarantee" @change="_calJatkotakuunKustannus()">
                </div>
                <span class="w-form-label">{{$t('options.yes')}}</span>
              </label>
              <label class="v-radio-button-field">
                <div class="v-radio-button" v-bind:class="{ 'v-radio-button-checked': form.guarantee === '0'}">
                  <input type="radio" data-name="Radio" value="0" v-model="form.guarantee" @change="_calJatkotakuunKustannus()">
                </div>
                <span class="w-form-label">{{$t('options.nope')}}</span>
              </label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 flex-center" v-if="calcVerotusarvo > settings.maxVerotusarvo">
            <div class="form-error col-xs-10 col-sm-8 col-md-7 flex-center">
              <span><strong>{{$t('form.field-10.label-over-1')}} {{$t('units.pre_unit_e_month')}}{{settings.maxVerotusarvo}} {{$t('units.unit_e_month')}}.</strong> <br> 
              {{$t('form.field-10.label-over-2')}}<br> 
              <!-- <small>{{$t('form.field-10.label-over-3')}}.</small> -->
              </span>
            </div>
          </div>
          <div class="col-xs-12 flex-center">
            <div class="form-success col-xs-10 col-sm-8 col-md-7 flex-center">
              <span>{{$t('form.field-10.label')}} <strong>{{$t('units.pre_unit_e_month')}}{{ roundedValue(calcVerotusarvo, 0) }} {{$t('units.unit_e_month')}}</strong></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<!--############################################################################## 
##########  ARVIO  ###############################################################
###############################################################################-->
<section class="info grey">
  <div class="container pb-5">
      <div class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-7 cont-boxes">
          <div class="colored-box light-blue left">

            <div class="row">
              <div class="col-xs-12">
                  <h3>
                  {{$t('estim.title')}} {{$t('units.pre_unit_e_month')}}{{ form.bike_price }} {{$t('units.unit_e')}}
                  </h3>
              </div>
            </div>

            <!-- Verotusarvo -->
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('estim.label-2')}} </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                  <!-- {{ roundedValue(+form.bike_price + +calKustannusIlmanLisapalv, 2) }} € -->
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{roundedValue(calcVerotusarvo,0)}} {{$t('units.unit_e_month')}}</span>
              </div>
            </div>

            <!-- Pyörän lyhennys: -->
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('addition.label-4')}} </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{ roundedValue(calCycleShortening, 0) }} {{$t('units.unit_e_month')}}</span>
              </div>
            </div>

            <!-- muu lyhennys: -->
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('addition.label-5')}} </span>
              <popper trigger="hover" :options="{placement: 'top'}">
                <div class="popper">
                  <span class="est-val">{{$t('addition.info-5')}}</span>
                </div>
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{ roundedValue(calServiceShortening, 0) }} {{$t('units.unit_e_month')}}</span>
              </div>
            </div>

            <!-- Palkka -->
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('form.field-3.label')}} </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{form.salary}} {{$t('units.unit_e_month')}}</span>
              </div>
            </div>

            <!-- Uusi palkka -->
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('estim.label-8')}} </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{roundedValue(calSalaryAfterBenefits,0)}} {{$t('units.unit_e_month')}}</span>
              </div>
            </div>


            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('influence.label-2')}} </span>
              <popper trigger="hover" :options="{placement: 'top'}">
                <div class="popper">
                  <span class="est-val">{{$t('influence.info-2')}}</span>
                </div>
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
              <span class="est-val">{{ roundedValue(((calTaxAfterBenefits * 100) - (calTaxBefore * 100)), 2) }}%</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('influence.label-3')}}</span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">
                  {{$t('units.pre_unit_e_month')}}{{ roundedValue(calTyontekijanKulut, 0)}}{{$t('units.unit_e')}}
                </span>
              </div>
            </div>
              
              
            <!-- Pyörän hinta -->
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                  <span class="est-label">{{$t('form.field-2.label')}}</span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{ form.bike_price }} {{$t('units.unit_e')}}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <!-- Kokonaiskustannus ilman lisäpalveluita -->
                  <span class="est-label">{{$t('estim.label-1')}}</span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <!-- {{ -1*calKustannusIlmanLisapalv }} € -->
                <span class="est-val">{{ form.agreement_period }}  {{$t('units.month')}}</span>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12">
                <div class="divider"></div>
              </div>
            </div>

                <!-- Työsuhdepyräpaketin kustannus ilman lunastusta -->
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('estim.label-5')}} </span>
              <popper trigger="hover" :options="{placement: 'top'}">
                <div class="popper">
                  {{$t('estim.info-5')}}
                </div>
                <i class="fas fa-question-circle infocircle" slot="reference"></i>
              </popper>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{ roundedValue(calKustannusIlmanLisapalv, 0) }} {{$t('units.unit_e')}}</span>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('influence.label-1')}} </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{ roundedValue(calSalaryAfterBenefits, 0) }}{{$t('units.unit_e_month')}} </span>
              </div>
            </div> -->

            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('estim.label-6')}} </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{ roundedValue(calLunastushinta, 0)  }} {{$t('units.unit_e')}}</span>
              </div>
            </div>
            
            <!-- Arvioitu kokonaissäästö -->
            <div class="row pb-4">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <span class="est-label">{{$t('estim.label-4')}} </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span class="est-val">{{$t('units.pre_unit_e_month')}}{{ roundedValue(calCalcSaving,0) }} {{$t('units.unit_e')}} 
                  ({{ roundedValue(calCalcSavingPros,2) }} %)
                </span>
              </div>
            </div>

          </div>

<!--############################################################################## 
##########  Lisäpalvelut  ########################################################
###############################################################################-->

          <div class="colored-box transparent left pb-0 mb-0">
            <div class="row">
              <div class="col-xs-12">
                <h4>
                {{$t('addition.title')}} 
                </h4>
              </div>
            </div>
            
            
            <!-- huoltobudjetti total -->
            <!-- <div class="row pb-4">
              <div class="col-xs-12 col-sm-12 col-md-8">
                {{$t('addition.label-4')}} 
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                {{$t('units.pre_unit_e_month')}}{{ roundedValue(calTotalMaintenanceBudget,0) }} {{$t('units.unit_e')}}
              </div>
            </div> -->

            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8">
                {{$t('addition.label-1')}} 
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                <span v-if="form.employer_interruption_insurance === '1'">{{$t('units.pre_unit_e_month')}}{{keskeytysturva}} {{$t('units.unit_e')}}, {{$t('addition.label-2')}} </span>
                <span v-if="form.employer_interruption_insurance === '0' && form.interruption_insurance === '1'">{{$t('units.pre_unit_e_month')}}{{keskeytysturva}} {{$t('units.unit_e')}}</span>
                <span v-if="form.employer_interruption_insurance === '0' && form.interruption_insurance === '0'"> - </span>
              </div>
            </div>

            <div class="row pb-4">
              <div class="col-xs-12 col-sm-12 col-md-8">
                <!-- Jatkotakuun kustannus -->
                {{$t('addition.label-3')}} 
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4">
                {{$t('units.pre_unit_e_month')}}{{ jatkotakuun_kustannus }} {{$t('units.unit_e')}}
              </div>
            </div>

          </div>
        </div>
        
        <div class="col-xs-12 col-sm-12 col-md-5">
          <!-- ContactForm -->
          <div class="colored-box right transparent">
            <h3>{{$t('cta.title')}}</h3>
            <p>{{$t('cta.text')}}</p>
            <div class=" mb-4">
              <a v-bind:href="$t('cta.link')" target="_blank" class="button">{{$t('cta.kalenterivaraus')}}</a> <br>
            </div>
          </div>
        </div>
      </div>
  </div>

</section>
<section class="disclaimer beige mt-5" >
  <div class="container">
    <!-- <div class="box beige 1text-start mb-5"> -->
    <div class="pt-5 pb-5 mb-3">
      <div class="row" id="disclaimer">
        <div class="col-12">
          <h3>{{$t('disclaimer.title')}}</h3>
        </div>
              
        <div class="col-12" v-html="$t('disclaimer.content')">

        </div>
      </div>
    </div>
  </div>
</section>

</div>
</template>

<script>
// #########################################################################################################
// #########################################################################################################
// ############# SCRIPTS ###################################################################################
// #########################################################################################################
// #########################################################################################################
import guaranteeData from '../assets/data/guarantee_continuation.json';
import taxData from '../assets/data/vero.json';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import { between } from 'vuelidate/lib/validators'

export default {
  name: "Calculator",
  components: {
      // Slider
      VueSlider,
      Popper
  },
  data() {
    return {
      form: {
        bike_price: 4000,
        bike_type: '1',
        salary: 3000,
        service_budget: 0,
        employer_benefit: 0,
        agreement_period: 48,
        employer_interruption_insurance: '1',
        interruption_insurance: '0',
        guarantee: '0'
      },
      ctaform: {
        company: '',
        first_name : '',
        last_name : '',
        email: ''
      },
      ctaFormStatus: null,
      employerData: {
        part_of_salary_benefit: 0,
        bike_benefit_compensation: 0,
        collectedCompensation	: 0
      },
      settings: {
        service_budgets: [0, 12, 16, 20],
        
        unit_e_month: '€/kk',
        asiakaspalvelumaksu: 1.0,
        vakuutus_vakio: 0.0296,
        vakio_1: 0.12,
        jaannosarvo: 0.05,
        keskeytysturva: 80,
        TyElx: 0.0855, // TyEL + työttömyysvakuutusmaksut
        TyEl: 0.0715, // Työttömyyseläkemaksu
        Tym: 0.014, // Työttömyysvakuutusmaksu
        maxVerotusarvo: 100,
        format: (value) => {
          
          return `${value} ${this.$i18n.messages[this.$i18n.locale].units.month}`;
        },
        format_e_kk: (value) => {
          return `${this.$i18n.messages[this.$i18n.locale].units.pre_unit_e_month}${value} ${this.$i18n.messages[this.$i18n.locale].units.unit_e_month}`;
        },
        debugMode: false,
        showCalculationProofs: false,
        zapUrl: ''
      },
      data: {
        taxData,
        guaranteeData,
      },
      lookupGuaranteeResult: {
        agreement_length: 0,
        bike: 0,
        e_bike: 0
      },
      lookupTaxResult: {
        palkka: 0,
        veroprosentti: 0
      },
      vakuutus: 0,
      rahoituskulu: 0,// this.settings.vakio_1*((this.bike_price-(this.bike_price*this.settings.jaannosarvo))/this.form.agreement_period),
      verotusarvo: 0,// ((this.bike_price-(this.settings.jaannosarvo*this.bike_price))/this.form.agreement_period+this.rahoituskulu+this.vakuutus+this.asiakaspalvelumaksu+this.form.service_budget)
      keskeytysturva: 0,
      jatkotakuun_kustannus: 0,
   }
  },
  validations: {
    form: {
      employer_benefit: {
        between: between(0, 100)
      }
    },
    // ctaform: {
    //   email: {
    //     required,
    //     email
    //   }
    // }
  },
  computed:{
    calcInsurance() {
      return this.form.bike_price* this.settings.vakuutus_vakio /12;
    },
    calBenefit() {
      return (+this.form.employer_benefit < 100 ) ? +this.form.employer_benefit : 100;
    },
    calCycleShortening() {
      return (+this.form.bike_price -( +this.form.bike_price*this.settings.jaannosarvo))/ +this.form.agreement_period;
    },
    calServiceShortening() {
      return this.roundedValue(+this.calcVerotusarvo,0) - this.roundedValue(+this.calCycleShortening,0);
    },
    calcRahoituskulu() {
      // return this.settings.vakio_1;
      return this.settings.vakio_1*((this.form.bike_price-(this.form.bike_price*this.settings.jaannosarvo))/this.form.agreement_period);
    },
    calcVerotusarvo() {
      //return Number(((this.form.bike_price-(this.settings.jaannosarvo*this.form.bike_price))/this.form.agreement_period+this.calcRahoituskulu+this.calcInsurance+this.settings.asiakaspalvelumaksu+this.form.service_budget)).toFixed(2);
      return Number(this.calCycleShortening+this.calcRahoituskulu+this.calcInsurance+this.settings.asiakaspalvelumaksu+ +this.form.service_budget).toFixed(2);
    },
    
    // calBikeCost() {
    //   return this.calcVerotusarvo()*this.form.agreement_period;
    // }
    calLunastushinta() {
      return Number(this.settings.jaannosarvo *this.form.bike_price).toFixed(4);
    },
    calKeskeytysturva() {
      return (this.form.bike_type === 1) ? this.settings.keskeytysturva: 0;
    },
    calJatkotakuunKustannus() {
      return (this.form.bike_type === 1) ? this.lookupGuaranteeResult.e_bike: this.lookupGuaranteeResult.bike;
    },
    calTaxBefore() {
      // console.log('this.settings.TyElx', this.settings.TyElx, this.lookupTaxResult.veroprosentti);
      // return Number(this.lookupTaxResult.veroprosentti - this.settings.TyElx).toFixed(4);
      return Number(this.lookupTaxResult.veroprosentti).toFixed(4);
    },
    calTaxesBefore() {
      return Number((+this.lookupTaxResult.veroprosentti - +this.settings.TyElx) * this.form.salary).toFixed(2);
    },
    calSalaryAfterBenefits() {
      return +this.form.salary + +this.calBenefit - +this.calcVerotusarvo;
    },
    calTaxAfterBenefits() {
      // return Number(this.lookupTaxProcentage(this.calSalaryAfterBenefits)  - this.settings.TyElx).toFixed(4);
      return Number(this.lookupTaxProcentage(this.calSalaryAfterBenefits)).toFixed(4);
    },
    calEtuVerollinen() {
      return 0 ;//bike_benefit_compensation;
    },
    calcEtuVerovapaa() {
      return Number(+this.calcVerotusarvo - +this.calBenefit).toFixed(2);
      // return Number(this.calcVerotusarvo).toFixed(2);
    },
    calRahapalkka() {
      // return +this.form.salary - +this.calcVerotusarvo;
      return +this.form.salary - +this.calcEtuVerovapaa;
    },
    calBruttopalkka() {
      return +this.calRahapalkka + +this.calEtuVerollinen;
    },
    calTyel() {
      return  Number((+this.form.salary - +this.calcVerotusarvo + +this.calcVerotusarvo ) * +this.settings.TyEl).toFixed(2);
    },
    calTym() {
      return Number((+this.calBruttopalkka + +this.calcVerotusarvo) * +this.settings.Tym ).toFixed(2);
    },
    calTyelx() {
      return Number(+this.form.salary * +this.settings.TyEl).toFixed(2);
    },
    calTymx() {
      // return Number(+this.form.salary * +this.settings.Tym ).toFixed(2);
      // return Number(+this.calBruttopalkka * +this.settings.Tym ).toFixed(2);
      return Number((+this.calBruttopalkka + +this.calcEtuVerovapaa) * +this.settings.Tym ).toFixed(2);
    },
    calEnnakonpidatys() {
      console.log('calEnnakonpidatys', +this.calBruttopalkka, +this.calTaxAfterBenefits, +this.settings.TyEl , +this.settings.Tym,
      (+this.calTaxAfterBenefits - +this.settings.TyEl - +this.settings.Tym), +this.calBruttopalkka * (+this.calTaxAfterBenefits - +this.settings.TyEl - +this.settings.Tym));
      return Number( +this.calBruttopalkka * (+this.calTaxAfterBenefits - +this.settings.TyEl - +this.settings.Tym)).toFixed(2);
    },
    calNettoPalkka() {
      return Number(+this.calRahapalkka - +this.calTyel - +this.calTymx - +this.calEnnakonpidatys).toFixed(2);
    },
    calNettopalkkaIlman() {
      return Number(+this.form.salary - +this.calTyelx - +this.calTymx - +this.calTaxesBefore).toFixed(2);
    },
    calMaksetaan() {
      return Number(+this.calNettoPalkka + +this.employerData.bike_benefit_compensation).toFixed(2);
    },
    calMaksetaanIlman() {
      return this.calNettopalkkaIlman;
    },
    calTyontekijanKulut() { // C78
      // return Number(+this.calMaksetaan - +this.calNettopalkkaIlman).toFixed(2);
      return Number(+this.calMaksetaan - +this.calMaksetaanIlman).toFixed(2);
    },
    calTyontekijanSaasto() { //C79
      // return Number(+this.settings.maxVerotusarvo + +this.calTyontekijanKulut).toFixed(2);
      return Number(+this.calcVerotusarvo + +this.calTyontekijanKulut).toFixed(2);
    },
    calKustannusIlmanLisapalv() {
      // return Number((+this.calcVerotusarvo - +this.calTyontekijanSaasto) * this.form.agreement_period).toFixed(2); 
      // let kilp = Number( +this.form.agreement_period * (+this.calTyontekijanKulut * -1)).toFixed(2);
      let kilp = Number( +this.form.agreement_period * (Math.round(+this.calTyontekijanKulut) * -1)).toFixed(2);
      return (+kilp > 0) ? +kilp : 0;
    },
    calKustannusLisapalvKanssa() {
      return Number( +this.calKustannusIlmanLisapalv - +this.keskeytysturva - +this.jatkotakuun_kustannus).toFixed(2);
    },
    calSaastoLisapalvKanssa() {
      return Number( +this.form.bike_price + +this.calKustannusLisapalvKanssa).toFixed(2);
    },
    // calJatkotakuun_kustannus() {
    //   this._calJatkotakuunKustannus();
    //   return this.jatkotakuun_kustannus;
    // }
    calCycleTotalPriceWithReclaim() {
      // return Number( +this.form.bike_price + +this.calLunastushinta).toFixed(2);
      let ctpwr = Number( +this.calKustannusIlmanLisapalv + +this.calLunastushinta).toFixed(2);
      return (ctpwr > 0) ? ctpwr : 0;
    },
    calCalcSaving() {
      // return Number((+this.form.bike_price - +this.calKustannusIlmanLisapalv)).toFixed(2); // poistettu 20211201
      return Number((+this.form.bike_price + (+this.form.agreement_period * +this.form.service_budget) ) - +this.calKustannusIlmanLisapalv).toFixed(2);
    },
    calCalcSavingPros() {
      // return Number( -1*(((+this.form.bike_price - +this.calTyontekijanSaasto)/ +this.form.bike_price)-1)*100 ).toFixed(2);
      // return Number( (((+this.calCalcSaving / +this.form.bike_price))*100) ).toFixed(2); // poistettu 20211201
      return Number( (((+this.calCalcSaving / (+this.form.bike_price + (+this.form.agreement_period * +this.form.service_budget) )))*100) ).toFixed(2);
    }
  },
  methods: {
    // _calcRahoituskulu() {
    //   // return this.settings.vakio_1;
    //   return  this.settings.vakio_1*((this.form.bike_price-(this.form.bike_price*this.settings.jaannosarvo))/this.form.agreement_period);
    // },
    // _calcVerotusarvo() {
    //   return ((this.form.bike_price-(this.settings.jaannosarvo*this.form.bike_price)) / this.form.agreement_period+this._calcRahoituskulu()+this.vakuutus+this.asiakaspalvelumaksu+this.form.service_budget);
    // },
    // _calBikeCost() {
    //   return this._calcVerotusarvo()*this.form.agreement_period;
    // },
    
    _calKeskeytysturva() {
      this.keskeytysturva = (+this.form.interruption_insurance === 1) ? this.settings.keskeytysturva: 0;
    },
    _calJatkotakuunKustannus() {
      let cost = (+this.form.bike_type === 1) ? this.lookupGuaranteeResult.e_bike: this.lookupGuaranteeResult.bike;
      // const exceedGuaranteeMonths = ((+this.form.agreement_period - 24) > 0) ? +this.form.agreement_period - 24 : 0;
      console.log('exceedGuaranteeMonths', +this.form.agreement_period, cost);
      if (this.form.guarantee === '1') {
        this.jatkotakuun_kustannus = +cost;
      } else {
        this.jatkotakuun_kustannus = 0;
      }
    },
    lookupGuarantee() {      
      // this.elmnIndex = this.data.guaranteeData.indexOf('9');
      // let valObj = this.data.guaranteeData.filter(function(elem) {
      //   console.log('elem', elem, this.form.agreement_period);
      //     if(+elem.agreement_length === +this.form.agreement_period) return elem;
      // });
      if (+this.form.agreement_period > 24) {
        this.data.guaranteeData.forEach(elem => {
            if(+elem.agreement_length === +this.form.agreement_period) {
              console.log('element **** TÄSSÄ *****', elem.agreement_length, +this.form.agreement_period);
              this.lookupGuaranteeResult = elem;
            }
        });
      } else {
        this.lookupGuaranteeResult = {
            "agreement_length": this.form.agreement_period,
            "bike": 0,
            "e_bike": 0
          };
      }
      
      console.log('lookupGuarantee',  this.form.agreement_period, this.lookupGuaranteeResult);
      // this.lookupResults = valObj;
      this._calJatkotakuunKustannus();
    },
    
    lookupTax() {
      // let rounded_salary = Math.round(+this.form.salary / 10) * 10;
      // let rounded_salary = Math.ceil(+this.form.salary / 10) * 10;
      let rounded_salary = parseInt(+this.form.salary / 10, 10) * 10;
      console.log('lookupTax', rounded_salary);
      this.data.taxData.forEach(elem => {
          if(+elem.palkka === +rounded_salary) {
            // console.log('element **** TÄSSÄ *****', elem.palkka, elem.veroprosentti);
            this.lookupTaxResult = elem;
          }
      });
    },
    
    lookupTaxProcentage(salary) {
      // let rounded_salary = Math.round(+salary / 10) * 10;
      // let rounded_salary = Math.ceil(+salary / 10) * 10;
      let rounded_salary = parseInt(+salary / 10, 10) * 10;
      let test = 0;
      this.data.taxData.forEach(elem => {
          if(+elem.palkka === +rounded_salary) {
            // console.log('element **** TÄSSÄ *****', elem.palkka, elem.veroprosentti);
      console.log('lookupTaxProcentage', rounded_salary, elem.veroprosentti);
            test = +elem.veroprosentti;
          }
      });
      return test;
    },
    setEmployer_interruption_insurance() {
      if (this.form.employer_interruption_insurance === '1') {
        this.form.interruption_insurance = '0';
        this._calKeskeytysturva();
      }
    },
    checkBikeType() {
      this._calJatkotakuunKustannus();
    },
    checkKeskeytysturva() {
      this._calKeskeytysturva();
    },
    roundedValue(val, desi) {
      return Number(+val).toFixed(+desi);
    },
    validateEmail() {
      
    },
    // sendForm() {
    //   // alert('Työnantaja ilmiannettu...');
    //   console.log('ctaform', this.ctaform);
      
    //   const formData = {
    //     "company": this.ctaform.company,   // -> create a new company to hubspot  -> associate to contact (email) created
    //     "first name": this.ctaform.first_name,    // -> associate to contact
    //     "last name": this.ctaform.last_name,     // -> associate to contact
    //     "email": this.ctaform.email         // -> create new contact -> associated to company created
    //   }


    //   // Converts an object to HTTP POST form data.
    //   const encodeForm = (data) => {
    //     return Object.keys(data)
    //       .map(
    //         (key) =>
    //           encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    //       )
    //       .join("&");
    //   };
      
    //   this.ctaFormStatus = 'PENDING';
    //   axios
    //     .post(
    //       this.settings.zapUrl,
    //       encodeForm(formData),
    //       {
    //         headers: { Accept: "application/json" },
    //       }
    //     )
    //     .then(function (response)  {
    //       // this.ctaFormStatus = 'OK';
    //       // console.log('axios.post', this.ctaFormStatus, response);
    //       console.log('axios.post', response);
    //     })
    //     .catch(function (error) {
    //       // this.ctaFormStatus = 'ERROR';
    //       console.log('axios.post', error);
    //     });
        
    //   this.ctaFormStatus = 'OK';
    // }
  },
  watch: {
  //       'form.salary': {
  //         handler: function(val, oldVal){
  //           if (+val > 4000) {
  //               // this.message= 'value too low!';
  //             console.log(+oldVal, +val);
  //           }
  //           // else{
  //           //   this.message= 'value is ok';
  //           // }
  //         },
  //         deep: true
  //       }
  
  },
  created() {
    this.lookupTax();
    this.lookupGuarantee();
    if (this.$route.query.debug=== 'true' ) {
      this.settings.debugMode = true;
    }
  }
};
</script>
<style src="vue-slider-component/theme/default.css"></style>
<style>
.container {
  position: relative;
}
.ingressi{
  font-size: 1.4rem;
  text-align: left;
  margin-bottom: 1.5rem;
}

.color-gray {
  color: #b1b1b1
}
.x10 {
  font-size: 175px;
}
button {
  color: #FFF;
  background: #2f4d52;
  border: none;
  border-radius: 30px  !important;
  padding: 15px 35px;
  margin: 10px;
  text-transform: uppercase;
  text-align: center;
  min-width: 125px;
  vertical-align: middle;
}
button i {
  margin-left: 4px;
}
button.secondary {
  border: 3px solid #2f4d52;
  background: #FFF;
  color: #2f4d52;
}
</style>