import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

import '@/assets/_base.scss';
import fi from '@/assets/locales/fi.json'
import en from '@/assets/locales/en.json'
Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(Vuelidate)

const messages = {
  fi: fi,
  en: en,
};

const i18n = new VueI18n({
      locale: localStorage.getItem('lang') || 'fi',
  messages
});
new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
