<template>
  <header>
    <div class="row">
      <div class="col-6 text-start">
        <div class="logo">
          <a v-bind:href="$t('links.logo')" target="_blanc">
            <img src="../assets/oomi-logo.svg" height="40" alt="Oomi logo" class="logo-header" />
          </a>
        </div>
      </div>
      <div class="col-6 text-end">
        <!-- <ul>
          <li><router-link to="/">Etusivu</router-link></li>
          <li><router-link to="/laskuri">Laskuri</router-link></li>
        </ul> -->
      
    <!-- <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        :value="lang">
          {{ lang }}
      </option>
    </select> -->

    <ul class="lang-selector">
      <li  v-bind:class="{ 'selected': $i18n.locale === 'fi'}">
        <div v-on:click="setLang('fi')">FI</div>
      </li>
      <li  v-bind:class="{ 'selected': $i18n.locale === 'en'}">
        <div v-on:click="setLang('en')">EN</div>
      </li>
    </ul>
  </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return { 
      locale: localStorage.getItem('lang') || 'fi',
      langs: ['fi', 'en'] 
    }
  },
  methods: {
    // localStorage.getItem('lang') || 'fi',
    setLang(lang){
      console.log('setLang', lang, this.locale);
      if (lang === 'en' || lang === 'fi') {
        localStorage.setItem('lang', lang);
        this.locale = lang;
        this.$i18n.locale = lang;
      }
    }
  }
};
</script>

<style>
header .logo {     padding: 16px 0 14px 0; }
header ul,
header ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}
header ul li a {
  margin: 0;
  padding: 25px 20px;
  display: inline-block;
  color: rgb(47, 77, 82);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
}
</style>